
import { computed, defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'HamburgerMenu',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const modelIsOpen = computed({
      get() {
        return props.isOpen;
      },
      set(value) {
        emit('update:isOpen', value);
      },
    });

    return {
      modelIsOpen,
    };
  },
});
