
import { computed, defineComponent } from '@nuxtjs/composition-api';

const to = {
  static: '',
  visible: 'opacity-0 group-hover/card:opacity-100',
  hide: 'opacity-100 group-hover/card:opacity-0',
};

export default defineComponent({
  props: {
    onHover: {
      type: String,
      default: 'static',
    },
  },
  setup(props) {
    const onHoverClasses = computed(() => {
      return to[props.onHover];
    });

    return {
      onHoverClasses,
    };
  },
});
