import { readBody, getRequestHeaders, getRequestURL } from 'h3';
import { $fetch } from 'ohmyfetch';

const ENDPOINTS = {
  genToken: '/get-token',
  getLocations: '/get-locations',
};
const UPS_URL = 'https://onlinetools.ups.com';
const getBasicAuthStringByCredentials = ({ user, password } = null) => {
  const auth = Buffer.from(`${user}:${password}`).toString('base64');
  return `Basic ${auth}`;
};
const getBearerAuthStringByAccessToken = (token) => {
  return `Bearer ${token}`;
};
const createNearestAccessPointsPayload = (
  addressLine,
  city,
  postalCode,
  countryCode,
  state,
  locale = 'en_US'
) => {
  const payload = {
    LocatorRequest: {
      Request: {
        RequestAction: 'Locator',
      },
      OriginAddress: {
        AddressKeyFormat: {
          AddressLine: addressLine,
          PoliticalDivision2: city,
          PoliticalDivision1: state,
          PostcodePrimaryLow: postalCode,
          CountryCode: countryCode,
        },
      },
      Translate: {
        Locale: locale,
      },
      LocationSearchCriteria: {
        MaximumListSize: '10',
        SearchRadius: '150',
      },

      UnitOfMeasurement: { Code: 'KM' },
      SortCriteria: { SortType: { SortType: '01' } },
    },
  };
  return payload;
};

const genToken = async (req, res) => {
  const reqBody = await readBody({
    node: { req },
    method: 'POST',
  });
  const reqHeaders = getRequestHeaders({ node: { req } });
  const response = await $fetch(`${UPS_URL}/security/v1/oauth/token`, {
    method: 'POST',
    body: new URLSearchParams(reqBody).toString(),
    headers: {
      'Content-Type': reqHeaders['content-type'],
      Authorization: getBasicAuthStringByCredentials({
        user: process.env.UPS_CLIENT_ID,
        password: process.env.UPS_CLIENT_SECRET,
      }),
    },
  });
  res.end(JSON.stringify(response));
};

const getLocations = async (req, res, next) => {
  const reqBody = await readBody({
    node: { req },
    method: 'POST',
  });
  try {
    const response = await $fetch(
      `${UPS_URL}/api/locations/v2/search/availabilities/64`,
      {
        method: 'POST',
        body: createNearestAccessPointsPayload(
          reqBody.data.addressLine,
          reqBody.data.city,
          reqBody.data.postalCode,
          reqBody.data.countryCode,
          reqBody.data.state
        ),
        headers: {
          'Content-Type': 'application/json',
          Authorization: getBearerAuthStringByAccessToken(reqBody.token),
        },
      }
    );
    res.end(JSON.stringify(response));
  } catch (error) {
    next(error);
  }
};

export default async (req, res, next) => {
  const url = getRequestURL({ node: { req } });
  if (url.pathname.indexOf(`${ENDPOINTS.genToken}`) !== -1)
    await genToken(req, res);
  if (url.pathname.indexOf(`${ENDPOINTS.getLocations}`) !== -1)
    await getLocations(req, res, next);
};
