import { ref, onMounted, onUnmounted, useStore } from '@nuxtjs/composition-api';

const breakpoints = {
  xs: 359,
  sm: 639,
  md: 767,
  lg: 1023,
  xl: 1279,
  '2xl': 1535,
  '3xl': 1783,
};

export function breakpoint(screen) {
  const store = useStore();
  const isBiggerThanBreakpoint = ref(store.getters['device/getIsPc']);

  const onResize = () => {
    isBiggerThanBreakpoint.value = window.innerWidth > breakpoints[screen];
  };

  onMounted(() => {
    onResize();
    window.addEventListener('resize', onResize, { passive: true });
  });

  onUnmounted(() => {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', onResize, { passive: true });
    }
  });

  return { isBiggerThanBreakpoint };
}
