/* eslint-disable no-shadow */
export const state = () => ({
  menuMain: [],
  menuFooter: [],
  footerCopyrightLinks: [],
  languageSelect: [],
});

export const getters = {
  getMenuMain(state) {
    return state.menuMain;
  },
  getMenuFooter(state) {
    return state.menuFooter;
  },
  getFooterCopyrightLinks(state) {
    return state.footerCopyrightLinks;
  },
  getLanguageSelect(state) {
    return state.languageSelect;
  },
};

export const mutations = {
  setMenus(state, menus) {
    state.menuMain.push(...(menus.menu_main?.[0].data || []));
    state.menuFooter.push(...(menus.menu_footer?.[0].data || []));
    state.languageSelect.push(...(menus.menu_header_1?.[0].data || []));
    state.footerCopyrightLinks.push(...(menus.menu_footer_2?.[0].data || []));
  },
};

export const actions = {
  async load({ commit }, { $api: { menusService } }) {
    try {
      const { data: menus } = await menusService.getMenusByKeys({
        key: ['menu_main', 'menu_footer', 'menu_footer_2', 'menu_header_1'],
      });
      commit('setMenus', menus);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  },
};
