import setGTMScript from '~/plugins/gtm/gtm-script';

export default ({ store }) => {
  const GTM_ID = store.getters['config/getGTM'];
  if (!GTM_ID) return;

  const body = document.querySelector('body');
  const firstChildOfBody = body.firstChild;

  const setIframeInBody = () => {
    const insertingNode = document.createElement('noscript');
    const iframe = document.createElement('iframe');
    iframe.src = `https://www.googletagmanager.com/ns.html?id=${GTM_ID}`;
    iframe.height = 0;
    iframe.width = 0;
    iframe.style = 'display:none;visibility:hidden';
    insertingNode.appendChild(iframe);
    body.insertBefore(insertingNode, firstChildOfBody);
  };

  setGTMScript(GTM_ID);
  setIframeInBody();
};
