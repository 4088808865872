import { useStore, computed, useContext } from '@nuxtjs/composition-api';

const useLanguages = () => {
  const { i18n } = useContext();
  const store = useStore();
  const menu = store.getters['menus/getLanguageSelect'];
  const currentLocale =
    i18n.locale || store.getters['config/getDefaultLanguage']?.code;

  const languages = computed(() => {
    return menu
      .filter((language) => language.badge_text)
      .map((language) => ({
        name: language.name,
        code: language.badge_text,
        domain: language.url,
      }));
  });

  return {
    languages,
    currentLocale,
  };
};

export default useLanguages;
