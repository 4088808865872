
import { ref, defineComponent, onMounted } from '@nuxtjs/composition-api';
import { useScroll } from '@vueuse/core';
import HamburgerMenu from '~/domains/header/components/HamburgerMenu';
import AppHeaderNav from '~/domains/header/components/AppHeaderNav';
import AppHeaderMobile from '~/domains/header/components/AppHeaderMobile';

export default defineComponent({
  name: 'AppHeader',
  components: { AppHeaderMobile, AppHeaderNav, HamburgerMenu },
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const isOpenMobileMenu = ref(false);

    let scrollElement = null;
    if (process.client) {
      scrollElement = window;
    }
    const { y } = useScroll(scrollElement);

    onMounted(() => {
      y.value = window.scrollY;
    });

    return {
      y,
      isOpenMobileMenu,
    };
  },
});
