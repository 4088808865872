
import { defineComponent } from '@nuxtjs/composition-api';
import AppFooterNav from '~/domains/footer/components/AppFooterNav';
import AppCopyright from '~/domains/footer/components/AppCopyright';

export default defineComponent({
  name: 'AppFooter',
  components: { AppCopyright, AppFooterNav },
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
    copyrightLinks: {
      type: Array,
      default: () => [],
    },
  },
});
