/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable-next-line */
import Vue from 'vue';
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  configure,
  localeChanged,
} from 'vee-validate';
import { required, email, alpha, max } from 'vee-validate/dist/rules';

extend('required', required);
extend('email', email);
extend('alpha', alpha);
extend('max', max);

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

export default ({ app }) => {
  configure({
    defaultMessage: (field, values) => {
      values._field_ = app.i18n.t(`${field}`);
      return app.i18n.t(`validation_${values._rule_}`, {
        count: values.length,
      });
    },
  });
  app.i18n.onLanguageSwitched = () => {
    localeChanged();
  };
};
