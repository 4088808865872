export default ({ req, beforeNuxtRender, nuxtState }, inject) => {
  let isGreece = false;
  if (process.server) {
    isGreece = ['localhost', 'lorgar.gr'].some((domain) =>
      req.headers.host.includes(domain)
    );
    beforeNuxtRender(({ nuxtState: renderNuxtState }) => {
      // eslint-disable-next-line no-param-reassign
      renderNuxtState.isGreece = isGreece;
    });

    inject('isGreece', isGreece);
  } else {
    isGreece = !isGreece
      ? ['localhost', 'lorgar.gr'].some((domain) =>
          window.location.host.includes(domain)
        )
      : isGreece;
    // eslint-disable-next-line no-undef
    if (!nuxtState?.baseURL) return;

    inject('isGreece', nuxtState.isGreece);
  }
};
