export const mutations = {
  setValue(state, payload) {
    // eslint-disable-next-line no-param-reassign
    state.isPc = payload;
  },
};

export const getters = {
  getIsPc(state) {
    return state.isPc;
  },
};

export const state = () => ({
  isPc: null,
});
