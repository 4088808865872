// eslint-disable-next-line import/no-extraneous-dependencies
import Vue from 'vue';
import GmapVue from 'gmap-vue';

export default ({ nuxtState }) => {
  Vue.use(GmapVue, {
    load: {
      key: nuxtState.config.gmapKey,
      libraries: 'places,visualization,drawing',
    },
    installComponents: true,
  });
};
