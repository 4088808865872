
import { defineComponent } from '@nuxtjs/composition-api';

const SVG_STYLE_MAP = {
  1: 'stroke-[2px] blur-[2px] fill-none',
  2: 'stroke-[6px] blur-[6px] fill-none',
  3: 'stroke-[5px] blur-[10px] fill-none',
  4: 'stroke-[5px] blur-[5px] fill-none',
};

export default defineComponent({
  setup() {
    return { SVG_STYLE_MAP };
  },
});
