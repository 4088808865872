
import { ref, defineComponent } from '@nuxtjs/composition-api';
import useLanguages from '~/composables/useLanguages';

export default defineComponent({
  setup() {
    const isOpenDropdown = ref(false);
    const { languages, currentLocale } = useLanguages();

    return {
      languages,
      currentLocale,
      isOpenDropdown,
    };
  },
});
