export default (
    { $axios, req, isDev, beforeNuxtRender, nuxtState },
    inject
) => {
    if(process.env.USE_HOST_URL === 'false') return;
    if (process.server) {
        const newBaseUrl = `${process.env.PROTOCOL}://${req.headers.host}`;

        if (!isDev) {
            $axios.interceptors.request.use(
                (config) => {
                    config.baseURL = newBaseUrl;
                    return config;
                },
                (error) => Promise.reject(error)
            );
        }
        beforeNuxtRender(({ nuxtState }) => {
            nuxtState.baseURL = newBaseUrl;
        });

        inject('baseURL', newBaseUrl);
    } else {
        if (!nuxtState?.baseURL) return;

        inject('baseURL', nuxtState?.baseURL);
    }
};
