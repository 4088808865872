export default ({ app: { $axios }, $config }, inject) => {
  const api = $axios.create({
    baseURL: $config.lorgarPlatformApiUrl,
  });

  const lorgarPlatformApi = () => ({
    getAwards: () => api.get('/v2/awards/invitation'),
    sendInvite: (email) => api.post('/v2/users/send-invitation', { email }),
  });

  inject('lorgarPlatformApi', lorgarPlatformApi());
};
