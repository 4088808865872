
import {
  ref,
  useContext,
  watch,
  useFetch,
  defineComponent,
} from '@nuxtjs/composition-api';
import { breakpoint } from '~/composables/breakpoint';

export default defineComponent({
  name: 'AppHeaderNav',
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const { route } = useContext();
    const activeRouteParams = ref(null);
    const hoveringIndex = ref(null);
    const dropdownActiveIndex = ref(null);
    const isOpenDropdown = ref(false);
    const { isBiggerThanBreakpoint } = breakpoint('xl');
    const activeLink = ref({});

    const findActiveInMenuChildren = (array) => {
      return array.find((item) => item.url === activeRouteParams.value);
    };

    const findActiveInMenu = (array) => {
      let active = {};
      array.forEach((item) => {
        if (item.children) {
          const result = findActiveInMenuChildren(item.children);
          if (result) {
            active = { ...result, parentID: item.id };
            return;
          }
        }

        if (item.url === activeRouteParams.value) {
          active = item;
        }
      });

      return active;
    };

    const changeActive = (index) => {
      activeRouteParams.value = index;
    };

    const enableHover = (index) => {
      if (isBiggerThanBreakpoint.value) {
        hoveringIndex.value = index;
      } else {
        hoveringIndex.value = null;
      }
    };

    const clearHover = () => {
      hoveringIndex.value = null;
    };

    const changeStateMobileDropdown = (item, index) => {
      if (item.children) {
        dropdownActiveIndex.value = index;
        isOpenDropdown.value = !isOpenDropdown.value;
      }
    };

    useFetch(() => {
      activeRouteParams.value = route.value.path;
    });

    watch(
      route,
      (to) => {
        activeRouteParams.value = to.path;
        activeLink.value = findActiveInMenu(props.menu);
        emit('onChangeRoute');
      },
      { immediate: true }
    );

    return {
      dropdownActiveIndex,
      isOpenDropdown,
      hoveringIndex,
      activeRouteParams,
      activeLink,
      changeActive,
      enableHover,
      clearHover,
      changeStateMobileDropdown,
    };
  },
});
