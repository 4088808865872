import { CdnImg } from '@webplatform/cdn-image';



export default {
  __name: 'UIImg',
  props: {
  width: {
    type: String,
    default: '',
  },
  height: {
    type: String,
    default: '',
  },
  src: {
    type: String,
    default: '',
  },
  loading: {
    type: String,
    default: 'lazy',
  },
  format: {
    type: String,
    default: 'webp',
  },
  placeholder: {
    type: [String, Boolean],
    default: '',
  },
  sizes: {
    type: String,
    default: '',
  },
  preset: {
    type: String,
    default: '',
  },
  quality: {
    type: String,
    default: '',
  },
  fit: {
    type: String,
    default: '',
  },
  preload: {
    type: Boolean,
    default: false,
  },
  alt: {
    type: String,
    default: 'image',
  },
},
  setup(__props) {



return { __sfc: true,CdnImg }
}

}