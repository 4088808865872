
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'AppHeaderModal',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
});
