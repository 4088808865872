
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    relative: {
      type: Boolean,
      default: true,
    },
  },
});
