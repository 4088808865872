
import { defineComponent } from '@nuxtjs/composition-api';
import AppHeaderNav from '~/domains/header/components/AppHeaderNav';
import AppHeaderModal from '~/domains/header/components/AppHeaderModal';

export default defineComponent({
  name: 'AppHeaderMobile',
  components: { AppHeaderModal, AppHeaderNav },
  props: {
    isOpenMobileMenu: {
      type: Boolean,
      default: false,
    },
    menu: {
      type: Array,
      default: () => [],
    },
  },
});
