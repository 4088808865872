// eslint-disable-next-line consistent-return,func-names
export default function (context) {
  if (context.$ua.deviceType() === 'pc') {
    context.store.commit('device/setValue', true);
  } else {
    context.store.commit('device/setValue', false);
  }
  // context.userAgent = process.server
  //   ? context.req.headers['user-agent']
  //   : navigator.userAgent;
}
