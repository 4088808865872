
import { defineComponent } from '@nuxtjs/composition-api';
import useSocial from '~/composables/useSocial';

export default defineComponent({
  setup() {
    const { social } = useSocial();

    return {
      social,
    };
  },
});
