import { useStore, ref } from '@nuxtjs/composition-api';

const useSocial = () => {
  const social = ref();
  const store = useStore();
  social.value = store.getters['config/getSocial'];

  return {
    social,
  };
};

export default useSocial;
