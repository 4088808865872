
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  layout: 'error',
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    return {
      props,
    };
  },
});
