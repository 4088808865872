const middleware = {}

middleware['checkDevice'] = require('../middleware/checkDevice.js')
middleware['checkDevice'] = middleware['checkDevice'].default || middleware['checkDevice']

middleware['trailingSlashRedirect'] = require('../middleware/trailingSlashRedirect.js')
middleware['trailingSlashRedirect'] = middleware['trailingSlashRedirect'].default || middleware['trailingSlashRedirect']

middleware['ups'] = require('../middleware/ups.js')
middleware['ups'] = middleware['ups'].default || middleware['ups']

export default middleware
