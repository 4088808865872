
import { defineComponent, computed } from '@nuxtjs/composition-api';
import { useCookies } from '@/node_modules/@vueuse/integrations/useCookies';

export default defineComponent({
  setup() {
    const cookies = useCookies(['accept-cookies']);
    const isNotAccept = computed(() => !cookies.get('accept-cookies'));

    const accept = () => {
      cookies.set('accept-cookies', true);
    };

    return {
      isNotAccept,
      accept,
    };
  },
});
