
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'AppFooterNav',
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
  },
});
