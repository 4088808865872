export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: false,
}
export const options = {
  vueI18n: {},
  vueI18nLoader: false,
  locales: [{"code":"en","domain":"lorgar.com","iso":"en_US","file":"getTranslations.js"},{"code":"bg","domain":"lorgar.bg","iso":"bg_BG","file":"getTranslations.js"},{"code":"cs","domain":"lorgar.cz","iso":"cs_CZ","file":"getTranslations.js"},{"code":"hu","domain":"lorgar.hu","iso":"hu_HU","file":"getTranslations.js"},{"code":"pl","domain":"stage-pl.lorgar.eu","iso":"pl_PL","file":"getTranslations.js"},{"code":"sk","domain":"lorgar.sk","iso":"sk_SK","file":"getTranslations.js"},{"code":"uk","domain":"lorgar.ua","iso":"uk_UA","file":"getTranslations.js"},{"code":"ro","domain":"lorgar.ro","iso":"ro_RO","file":"getTranslations.js"},{"code":"ru","domain":"ru.lorgar.eu","iso":"ru_RU","file":"getTranslations.js"},{"code":"es","domain":"lorgar.es","iso":"es_ES","file":"getTranslations.js"},{"code":"de","domain":"lorgar.de","iso":"de_DE","file":"getTranslations.js"},{"code":"el","domain":"lorgar.gr","iso":"el_GR","file":"getTranslations.js"},{"code":"kz","domain":"lorgar.kz","file":"getTranslations.js"},{"code":"lv","domain":"lorgar.lv","iso":"lv_LV","file":"getTranslations.js"}],
  defaultLocale: false,
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "locales/",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: true,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"en","domain":"lorgar.com","iso":"en_US","file":"getTranslations.js"},{"code":"bg","domain":"lorgar.bg","iso":"bg_BG","file":"getTranslations.js"},{"code":"cs","domain":"lorgar.cz","iso":"cs_CZ","file":"getTranslations.js"},{"code":"hu","domain":"lorgar.hu","iso":"hu_HU","file":"getTranslations.js"},{"code":"pl","domain":"stage-pl.lorgar.eu","iso":"pl_PL","file":"getTranslations.js"},{"code":"sk","domain":"lorgar.sk","iso":"sk_SK","file":"getTranslations.js"},{"code":"uk","domain":"lorgar.ua","iso":"uk_UA","file":"getTranslations.js"},{"code":"ro","domain":"lorgar.ro","iso":"ro_RO","file":"getTranslations.js"},{"code":"ru","domain":"ru.lorgar.eu","iso":"ru_RU","file":"getTranslations.js"},{"code":"es","domain":"lorgar.es","iso":"es_ES","file":"getTranslations.js"},{"code":"de","domain":"lorgar.de","iso":"de_DE","file":"getTranslations.js"},{"code":"el","domain":"lorgar.gr","iso":"el_GR","file":"getTranslations.js"},{"code":"kz","domain":"lorgar.kz","file":"getTranslations.js"},{"code":"lv","domain":"lorgar.lv","iso":"lv_LV","file":"getTranslations.js"}],
  localeCodes: ["en","bg","cs","hu","pl","sk","uk","ro","ru","es","de","el","kz","lv"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "g",
  3: "e",
  4: "t",
  5: "T",
  6: "r",
  7: "a",
  8: "n",
  9: "s",
  10: "l",
  11: "a",
  12: "t",
  13: "i",
  14: "o",
  15: "n",
  16: "s",
  17: ".",
  18: "j",
  19: "s",
  20: "\"",
  21: ":",
  22: "\"",
  23: ".",
  24: ".",
  25: "/",
  26: "l",
  27: "o",
  28: "c",
  29: "a",
  30: "l",
  31: "e",
  32: "s",
  33: "/",
  34: "g",
  35: "e",
  36: "t",
  37: "T",
  38: "r",
  39: "a",
  40: "n",
  41: "s",
  42: "l",
  43: "a",
  44: "t",
  45: "i",
  46: "o",
  47: "n",
  48: "s",
  49: ".",
  50: "j",
  51: "s",
  52: "\"",
  53: "}",
}

export const localeMessages = {
  'getTranslations.js': () => import('../../locales/getTranslations.js' /* webpackChunkName: "lang-getTranslations.js" */),
}
