
import { defineComponent } from '@nuxtjs/composition-api';

const ANIMATES = {
  default: 'animate-[animate-bg-lines_25s_linear_infinite]',
  'animate-rotate-70': 'animate-[animate-bg-lines-70_25s_linear_infinite]',
  'animate-rotate-225': 'animate-[animate-bg-lines-225_25s_linear_infinite]',
  'animate-rotate-327': 'animate-[animate-bg-lines-327_25s_linear_infinite]',
  'animate-rotate-45': 'animate-[animate-bg-lines-45_25s_linear_infinite]',
  'animate-rotate-15': 'animate-[animate-bg-lines-15_25s_linear_infinite]',
};

export default defineComponent({
  props: {
    custom: {
      type: Boolean,
      default: false,
    },
    animate: {
      type: String,
      default: 'default',
    },
  },
  setup(props) {
    const currentAnimate = ANIMATES[props.animate];

    return { currentAnimate };
  },
});
