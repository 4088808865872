
import { computed, defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  props: {
    to: {
      type: [String, Object],
      default: null,
    },
    target: {
      type: String,
      default: '_self',
    },
  },
  setup(props) {
    const isExternalLink = computed(() => {
      if (typeof props.to !== 'string') return false;

      return props.to.startsWith('https://') || props.to.startsWith('http://');
    });

    return {
      isExternalLink,
    };
  },
});
